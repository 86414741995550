.navbar {
  position: absolute !important;
  top: 0;
  z-index: 999;
  transition: all 0.5s ease-in-out;
  color: var(--color-white) !important;
  width: 100%;
  margin-top: -12px;
}

.navbar.navbar-sticky,
.navbar.navbar-2 {
  background-color: var(--color-white) !important;
  position: sticky !important;
  transition: all 0.5s ease-in-out;
}

.navbar.navbar-sticky .logo-text,
.navbar .logo-text,
.navbar.navbar-2 .logo-text {
  font-size: 1.5rem;
  font-weight: 600;
}

.navbar.navbar-sticky .logo-text,
.navbar.navbar-2 .logo-text {
  color: var(--dark-blue) !important;
}

.navbar .logo-text {
  color: var(--color-white) !important;
}

.navbar.navbar-sticky .img-bg {
  background-color: transparent;
}

.navbar .img-bg {
  background-color: var(--color-white);
  border-radius: 50%;
  padding: 13px 3px;
  margin-right: 5px;
}

.navbar .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar .container .nav__items {
  display: flex;
  gap: 3rem;
  align-items: center;
  margin-bottom: 0;
}

.navbar .container button {
  display: none;
}

.navbar.navbar-sticky .container .nav__items a,
.navbar .container .nav__items a,
.navbar.navbar-2 .container .nav__items a {
  font-weight: 400;
  font-size: 0.9rem;
  position: relative;
  text-transform: capitalize;
}

.navbar.navbar-sticky .container .nav__items a,
.navbar.navbar-2 .container .nav__items a,
.navbar .container .nav__items .dropdown-content a {
  color: var(--dark-blue);
}

.navbar .container .nav__items a {
  color: var(--color-white);
}

.navbar .container .nav__items a:hover {
  color: var(--sea-blue);
}

.navbar .container .nav__items .active,
.navbar.navbar.navbar-sticky .container .nav__items .active,
.navbar.navbar.navbar-2 .container .nav__items .active {
  color: var(--sea-blue);
}

.navbar .container .nav__items .active::after {
  color: var(--color-white);
  width: 50%;
}

.navbar .container .nav__items a::after {
  content: "";
  width: 0;
  height: 3px;
  position: absolute;
  bottom: -5px;
  left: 25%;
  transform: translateX(-50%);
  /* background: var(--color-white); */
  transition: width 0.3s;
}

.navbar .container .nav__items a:hover::after {
  width: 50%;
}

.navbar .container .nav__logo img {
  width: 3.6rem;
}

.navbar .dropdown {
  position: relative;
  display: inline-block;
}

.navbar .dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.navbar .dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.navbar .dropdown-content a:hover {
  background-color: #ddd;
  color: var(--dark-blue) !important;
}

.navbar .dropdown:hover .dropdown-content {
  display: block;
}

/*============= MEDIA QUERIES (MEDIUM DEVICES) ================*/

@media screen and (max-width: 1024px) {
  .navbar {
    position: fixed;
  }

  .navbar .container button {
    display: inline-block;
    background: transparent;
    color: var(--dark-blue);
    font-size: 1.5rem;
    font-weight: 200;
    cursor: pointer;
  }

  .navbar .container .nav__items {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: rgba(255, 255, 255, 0.9);
    /* box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37); */
    backdrop-filter: blur(5px);
    border-radius: 0 0 15px 15px;
    width: 100%;
    height: 100vh;
    position: absolute;

    left: -100%;
    padding: 80px 0 30px 0;
    margin: 0;
    z-index: -1;
    transition: all 0.3s ease-in-out;

    top: 5rem;
    align-items: flex-start;
    padding-left: 20%;
  }

  .navbar .container .show__nav {
    display: flex;
    left: 0%;
    top: 0;
  }

  .navbar.navbar.navbar-sticky .container .show__nav,
  .navbar.navbar-2 .container .show__nav {
    top: 100%;
  }

  .navbar .container .hide__nav {
    display: none;
  }

  .navbar .container .nav__items a {
    color: var(--dark-blue);
  }

  .navbar.container .nav__items li {
    display: block;
    padding: 2rem 0;
    width: 100%;
  }

  .logo-text {
    font-size: 1.2rem !important;
  }

  .navbar .container .nav__logo img {
    width: 3.2rem !important;
  }
}

@media (max-width: 769px) {
  .navbar .container .nav__logo img {
    width: 3rem !important;
  }
}

/*============= MEDIA QUERIES (SMALLER DEVICES) ================*/

@media screen and (max-width: 600px) {
  .navbar .container .nav__logo img {
    width: 2.7rem !important;
  }

  .navbar .container .nav__items .active {
    color: var(--sea-blue);
  }

  .logo-text {
    font-size: 1rem;
  }
}
